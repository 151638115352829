<script setup lang="ts">
  import {ref} from 'vue';
  import { useTranslation } from "i18next-vue";
  import { resourceCenterTextEmun } from "../translations/lang/translation-keys.js";
  let emit = defineEmits<{ (e: 'update:modelValue', value: string): void }>();

  const searchActive = ref(false);
  const searchValue = ref("");

  function toggleSearch() {
    searchActive.value = !searchActive.value;
  }
  
  function applySearch(value: string) {
    searchValue.value = value;
    emit('update:modelValue', value);
  }
  function triggerSearch($event){
    applySearch(($event.target as HTMLInputElement).value);
  }
  const {t} = useTranslation();
</script>

<template>
  <div id="resource-search" 
    class="resource-search"
    :class="{ active: searchActive }"
  >
    <input v-model="searchValue"
      class="resource-search-input"
      type="text"
      name=""
      :placeholder="t(resourceCenterTextEmun.SearchResources)"
      @keyup.enter="triggerSearch($event)"
    >
    <svg class="search-glass"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg" 
      @click="($event) => { toggleSearch(); applySearch(searchValue); }"
    >
      <path d="M15.9062 14.6562C15.9688 14.7188 16 14.8125 16 14.9062C16 15.0312 15.9688 15.125 15.9062 15.1875L15.1875 15.875C15.0938 15.9688 15 16 14.9062 16C14.7812 16 14.7188 15.9688 14.6562 15.875L10.8438 12.0938C10.7812 12.0312 10.75 11.9375 10.75 11.8438V11.4062C10.1562 11.9062 9.5 12.3125 8.78125 12.5938C8.03125 12.875 7.28125 13 6.5 13C5.3125 13 4.21875 12.7188 3.21875 12.125C2.21875 11.5625 1.4375 10.7812 0.875 9.78125C0.28125 8.78125 0 7.6875 0 6.5C0 5.3125 0.28125 4.25 0.875 3.25C1.4375 2.25 2.21875 1.46875 3.21875 0.875C4.21875 0.3125 5.3125 0 6.5 0C7.6875 0 8.75 0.3125 9.75 0.875C10.75 1.46875 11.5312 2.25 12.125 3.25C12.6875 4.25 13 5.3125 13 6.5C13 7.3125 12.8438 8.0625 12.5625 8.78125C12.2812 9.53125 11.9062 10.1875 11.4062 10.75H11.8438C11.9375 10.75 12.0312 10.7812 12.0938 10.8438L15.9062 14.6562ZM6.5 11.5C7.375 11.5 8.21875 11.2812 9 10.8438C9.75 10.4062 10.375 9.78125 10.8125 9C11.25 8.25 11.5 7.40625 11.5 6.5C11.5 5.625 11.25 4.78125 10.8125 4C10.375 3.25 9.75 2.625 9 2.1875C8.21875 1.75 7.375 1.5 6.5 1.5C5.59375 1.5 4.75 1.75 4 2.1875C3.21875 2.625 2.59375 3.25 2.15625 4C1.71875 4.78125 1.5 5.625 1.5 6.5C1.5 7.40625 1.71875 8.25 2.15625 9C2.59375 9.78125 3.21875 10.4062 4 10.8438C4.75 11.2812 5.59375 11.5 6.5 11.5Z"
        fill="#164A62" 
      />
    </svg>
  </div>
</template>