export type RecordWithExactKeys<KeysListUnion extends string, ValueType> = {
    [Key in KeysListUnion]: ValueType;
};

export const ClassificationProps = ["hubType", "solution", "industry"] as const;
export type ClassificationProps = typeof ClassificationProps[number]; // Create a union type from the array

export type ClassificationsRecord<ValueType> = RecordWithExactKeys<ClassificationProps, ValueType>;


export const FilterProps = ["searchTerm"].concat(ClassificationProps);
export type FilterProps = typeof FilterProps[number];