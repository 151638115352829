import { default as i18next, type ResourceKey, type CallbackError } from "i18next";
import I18NextVue from "i18next-vue";
import { default as resourcesToBackend } from "i18next-resources-to-backend";
import type { App } from "vue";
import { BrowserEnv } from "../../utils/browser_env.js";
import type { LanguageCountryLocale } from "../../types-and-definitions/locale-defs/Locales.js";
import { TranslationNamespaceEnum } from "./settings/translation-namespaces.js";


const lang = (window.AppState.GetCurrentLanguageName() === "en" ? "en-US" : window.AppState.GetCurrentLanguageName()).toLowerCase();
const i18nextInstance = i18next.createInstance()
  .use(
    resourcesToBackend((language: string, namespace: string, callback) => {
      const langFolder = language.toLowerCase() as Lowercase<LanguageCountryLocale | 'en'>;
      console.log(langFolder, namespace);
      import(`/assets/js/resource-center/translations/lang/${langFolder}/${namespace}.js`)
        .then(({ default: resources }: { default: ResourceKey | boolean | null | undefined }) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error as CallbackError, null);
        });
    })
  );
// init i18next. For all options read: https://www.i18next.com/overview/configuration-options
 await i18nextInstance
  .init({
    lng: lang,
    debug: BrowserEnv.branch === "dev",
    load: "currentOnly",
    fallbackLng: "en-US",
    supportedLngs: ["en", "en-US", "en-AU", "en-GB", "ja-JP"] satisfies (LanguageCountryLocale | "en")[],
    partialBundledLanguages: true,
    ns: TranslationNamespaceEnum.resourceCenterText, //currenly only one ns
    keySeparator: false,
    nsSeparator: ":"
  });

export function initI18NextVue(app: App) {
  app.use(I18NextVue, { i18next: i18nextInstance });
  return app;
}
