<script setup lang="ts">
  defineProps<{
    title: string,
    filtername: string
  }>();

  let emit = defineEmits<(e: 'remove', { filtername }: { filtername: string }) => void>();

  function remove(filtername: string) {
    emit('remove', { filtername });
  }
</script>

<template>
  <span class="filter-pill" @click="() => remove(filtername)">
    {{ title }}
  </span>
</template>