import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "dropdown d-none d-md-block" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["aria-labelledby"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  class: "dropdown-item disabled dropdown-empty",
  href: "javascript:void(0);"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: $props.filtername,
      class: "dropdown-toggle",
      type: "button",
      "data-bs-toggle": "dropdown",
      "aria-haspopup": "true",
      "aria-expanded": "false"
    }, _toDisplayString($setup.t($props.title)), 9 /* TEXT, PROPS */, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown-menu r-scroll", { empty: $props.hasNoOptions }]),
      "aria-labelledby": $props.filtername
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.opts, (opt) => {
        return (_openBlock(), _createElementBlock("a", {
          key: opt,
          class: "dropdown-item",
          href: "javascript:void(0);",
          onClick: $event => ($setup.filterUpdated(opt))
        }, _toDisplayString(opt), 9 /* TEXT, PROPS */, _hoisted_4))
      }), 128 /* KEYED_FRAGMENT */)),
      _createElementVNode("a", _hoisted_5, _toDisplayString($setup.t($setup.resourceCenterTextEmun.RemoveFilters)), 1 /* TEXT */)
    ], 10 /* CLASS, PROPS */, _hoisted_3)
  ]))
}