<script setup lang="ts">
  import { type Resource } from '../data-store/resource-center-store.js';
  import imageWithFallback from '../../shared-components/image-with-fallback.vue';
  import { useTranslation } from "i18next-vue";
  import { resourceCenterTextEmun } from "../translations/lang/translation-keys.js";
  
  defineProps<{
    resource: Resource,
    genericThumbSrc: string,
  }>();
  const { t } = useTranslation();
</script>

<template>
  <a v-if="!!resource && resource.landingPageUrl !== null && resource.hubType !== null"
    class="resource-card"
    :class="resource.hubType.replace(/\s+/g, '-').toLowerCase() + '-parent'"
    :href="resource.landingPageUrl"
    :target="resource.isExternal ? '_blank' : ''" 
    :rel="resource.isExternal ? 'noopener noreferrer' : ''"
  >
    <p v-if="resource.hubType === 'Upcoming Event'" class="event-time">
      {{ resource.startDateAndTimeFormatted?.date }}<span class="pipe">|</span><br> {{ resource.startDateAndTimeFormatted?.time }}
    </p>
    <div class="img-wrpr">
      <imageWithFallback v-if="resource.hubType === 'Link'"
        class="link-img"
        :src="resource.thumbnail"
        :alt="resource.thumbnailAltText"
        :fallback-src="genericThumbSrc"
        width="200"
      />
      <imageWithFallback v-else
        class="resource-img"
        :src="resource.thumbnail"
        :alt="resource.thumbnailAltText"
        :fallback-src="genericThumbSrc"
        height="160"
      />
      <div v-if="resource.hubType.includes('Video') || resource.hubType === 'On-Demand'"
        class="play-button"
      >
        <span>{{ t(resourceCenterTextEmun.Play) }}</span>
        <svg xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="10"
          viewBox="0 0 7 10"
          fill="none"
        >
          <path d="M7 5L0.25 9.33013L0.25 0.669872L7 5Z" />
        </svg>
      </div>
    </div>
    <div class="resource-content">
      <div v-if="resource.hubType ==='Link'" class="asset-type-label" :class="resource.hubType.replace(/\s+/g, '-').toLowerCase()">
        {{ resource.assetType }}
      </div>
      <div v-else class="asset-type-label" :class="resource.hubType.replace(/\s+/g, '-').toLowerCase()">
        {{ resource.hubType }}
      </div>
      <div v-if="resource.hubType === 'Upcoming Event' || resource.hubType === 'On-Demand'" class="resource-title">
        {{ resource.eventHeading }}
      </div>      
      <div v-else-if="resource.hubType === 'Link'" class="resource-title">
        {{ resource.heading }}
      </div>
      <div v-else class="resource-title">
        {{ resource.title }}
      </div>

      <div class="resource-desc">
        {{ resource.description }}
      </div>

      <p v-if="resource.hubType === 'Upcoming Event'" class="eventLink">
        {{ resource.cardCtaLabel }}
      </p>

    </div>
  </a>
</template>