import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("img", {
    src: $setup.activeImageSrc,
    alt: ($props.alt ?? undefined),
    width: "160",
    height: "207",
    onErrorOnce: _cache[0] || (_cache[0] = $event => ($setup.useFallbackImage()))
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1))
}