import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "select-wrpr" }
const _hoisted_2 = ["id", "name"]
const _hoisted_3 = {
  value: "",
  selected: "",
  hidden: ""
}
const _hoisted_4 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("select", {
      id: 'mobile-' + $props.filtername,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.selected) = $event)),
      class: "filter-select",
      name: 'mobile-' + $props.filtername,
      onChange: $setup.switchSelect
    }, [
      _createElementVNode("option", _hoisted_3, _toDisplayString($props.title), 1 /* TEXT */),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.opts, (opt) => {
        return (_openBlock(), _createElementBlock("option", {
          key: opt,
          value: opt
        }, _toDisplayString(opt), 9 /* TEXT, PROPS */, _hoisted_4))
      }), 128 /* KEYED_FRAGMENT */)),
      _createElementVNode("option", {
        class: _normalizeClass(["dropdownEmpty", { empty: $props.hasNoOptions }]),
        disabled: ""
      }, _toDisplayString($setup.t($setup.resourceCenterTextEmun.RemoveFilters)), 3 /* TEXT, CLASS */)
    ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_2), [
      [_vModelSelect, $setup.selected]
    ])
  ]))
}