<script setup lang="ts">
  import { ref } from 'vue';
  import {FilterProps} from "./shared-types.js";
  import { useTranslation } from "i18next-vue";
  import { resourceCenterTextEmun } from "../translations/lang/translation-keys.js";
  const props = defineProps<{
    opts: string[],
    title: string,
    filtername: FilterProps,
    hasNoOptions: boolean
  }>();

  const selected = ref('');

  const emit = defineEmits<(e: 'clicked', { filtername, option }: { filtername: FilterProps, option: string }) => void>();

  function filterUpdated(option: string) {
    selected.value = '';
    console.warn('filter updated');
    emit('clicked', {
      filtername: props.filtername,
      option
    });
  }
  console.warn(props.title);
  const { t } = useTranslation();
</script>

<template>
  <div class="dropdown d-none d-md-block">
    <div :id="filtername" 
      class="dropdown-toggle"
      type="button"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ t(title) }}
    </div>
    <div class="dropdown-menu r-scroll" 
      :aria-labelledby="filtername" 
      :class="{ empty: hasNoOptions }"
    >
      <a v-for="opt in opts" 
        :key="opt" 
        class="dropdown-item"
        href="javascript:void(0);"
        @click="filterUpdated(opt)"
      >
        {{ opt }}
      </a>
      <a class="dropdown-item disabled dropdown-empty" href="javascript:void(0);">
        {{ t(resourceCenterTextEmun.RemoveFilters) }}
      </a>
    </div>
  </div>
</template>