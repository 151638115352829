<script setup lang="ts">
import { TranslationComponent as translation, useTranslation } from "i18next-vue";
import { resourceCenterTextEmun } from "../translations/lang/translation-keys.js";
defineProps<{
currentPage: number,
pageCount: number,
}>();
const {t} = useTranslation();
</script>
<template>
    <span class="page-numbers">
    <translation :translation="t(resourceCenterTextEmun.PageIndicator)" >
        <template #current-page-slot>{{ currentPage }}</template>
        <template #max-page-slot>{{ pageCount }}</template>
    </translation>
    </span>
</template>