import { createApp, type Component } from 'vue';
import _appResourceCenter from "./App.resource-center.vue";
import { createPinia } from 'pinia';
import { initI18NextVue } from "./translations/initialize-translations.js";
// import 'bootstrap';

export function initializeResoureceCenter() {
  const appResourceCenter = _appResourceCenter as Component;

  const app = createApp(appResourceCenter);
  initI18NextVue(app);
  const pinia = createPinia();
  app.use(pinia);

  app.mount('#app');
  app.config.errorHandler = (err) => {
    console.error(err);
  };
}