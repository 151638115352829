<script setup lang="ts">
  import { ref, computed, reactive, watch } from 'vue';
  import { isArrayOfType, isNil, isObject, isString } from '@mcwd/typescript-type-guards';

  // components
  import resultsPagination from './results-pagination.vue';
  import desktopFilterDropdown from './desktop-filter-dropdown.vue';
  import mobileFilterDropdown from './mobile-filter-dropdown.vue';
  import filterPill from './filter-pill.vue';
  import cardOrListViewToggle from './card-or-list-view-toggle.vue';
  import searchBox from "./resource-search-box.vue";
  import resourceCard from './resource-card.vue';
  import imageWithFallback from '../../shared-components/image-with-fallback.vue';
  // translation stuff
  import { useTranslation } from "i18next-vue";
  import { resourceCenterTextEmun } from "../translations/lang/translation-keys.js";

  // data
  import { useResouceCenterStore, type Resource } from "../data-store/resource-center-store.js";

  // types and props
  import { type RecordWithExactKeys, type ClassificationsRecord, ClassificationProps, FilterProps} from "./shared-types.js";

  // Non-reactive
  const RESULTS_PER_PAGE = 10;
  const CURRENT_LANG = window.AppState.GetCurrentLanguageName().toLowerCase();

  // Data store
  const resourceCenterStore = useResouceCenterStore();

  // reactive references
  const loaded = ref(false);
  const allResources = ref(([] as Resource[]));
  // const topThree = ref(null as [Resource, Resource, Resource] | null);
  const topThreeAll = ref(([] as Resource[]));
  const topThreeLang = ref(([] as Resource[]));
  const displayAsList = ref(false);
  const currentPage = ref(1);

  // Fetch data from store
  resourceCenterStore.fetchResources()
    .then(() => {
      allResources.value = resourceCenterStore.resources ?? [];
      // topThree.value = (resourceCenterStore.topThreeResources as [Resource, Resource, Resource]) ?? null;
      topThreeAll.value = resourceCenterStore.topThreeResourcesAll ?? [];
      filteredTopThree();
      // console.warn('topThreeAllValue: ', topThreeAll.value);

      // Remove resources that are expired (mainly upcoming events)
      var now = new Date();
      allResources.value = allResources.value.filter(el => {
        return isNil(el.hubExpiration) || el.hubExpiration > now;
      });
      loaded.value = true;
      checkForUrlQuery();
    })
    .catch(err => {
      console.error(err);
    });

  /**
   * Convert a json property name from a Resource into a title for the filter dropdowns and pills
   * @param propName
   */
  const getDropdownTitle = (propName: ClassificationProps) => {
    switch (propName) {
      case "hubType": return "Type";
      default: return (propName[0].toLocaleUpperCase() + propName.substring(1));
    }
  };

  /**
   * Reactive reference: 
   * - Each key specifies a filter value to apply against the list of resources.
   * - Values set to empty string are ignored.
   */
  const filters = reactive<RecordWithExactKeys<FilterProps, string>>({
    searchTerm: '',
    hubType: '',
    solution: '',
    industry: ''
  });

  /**
   * Reactive reference:
   * - Specifies whether there are any classifications to display in a filter dropdown 
   */
  const isEmptyClassification = reactive<ClassificationsRecord<boolean>>({ 
    hubType: true,
    solution: true,
    industry: true
  });

  /**
   * Type: A function type declaration for a function that checks if a string `value` matches the expected string in `valueToMatch`
   * @param valueToMatch - The string value to test against (aka the filter)
   * @param value - The string value we want to test
   * @returns {boolean} - Returns true if `value` matches `valueToMatch`
   */
  type StringMatchFn = (valueToMatch: string, value: string) => boolean;
  
  /**  Checks for exact equality between two strings  */
  const checkValueEquals: StringMatchFn   = (valueToMatch, value) => (value === valueToMatch);

  /**  Checks if a string is a substring of another string  */
  const checkValueContains: StringMatchFn = (valueToMatch, value) => ((value ?? "").indexOf(valueToMatch) !== -1);

  /**
   * A type matching any valid property name of the Resource type.
   */
  type ResourceKey = (keyof Resource);

  /**
   * - Filter each resource with a stringMatch function on each specified property
   * - If the value doesn't match the `valueToMatch` filter string, the resource is filtered out.
   * @param valueToMatch - The string value to test against (aka the filter)
   * @param resources - The list of resources to filter
   * @param propsToCheck - A list of properties to test against the filter string 
   * @param stringMatchFn - The match function used to check if the Resource property(s) contain a match, or if they should be filtered out
   * @returns - The filtered list of resources
   */
  const filterResourcesByPropValues = (valueToMatch: string, resources: Resource[], propsToCheck: ResourceKey[], stringMatchFn: StringMatchFn): Resource[] => {
    valueToMatch = valueToMatch?.toLowerCase();
    if (isString(valueToMatch) && valueToMatch.length > 0) {
      return resources.filter((r) => {
        // put all resource values into a flat string array
        let allValuesToCheck: string[] = [];

        for (let prop of propsToCheck) {
          let val = [r[prop]].flat(); // Wrap into flat array
          if (isArrayOfType<string>(val, (v) => (isString(v) && v.length > 0))) { // Require an array of strings with length > 0
            allValuesToCheck = allValuesToCheck.concat(val.map(v => v.toLowerCase()));
          }
        }
        // If there are no string values in allValuesToCheck, then nothing matches the valueToMatch string.
        if (allValuesToCheck.length === 0) {
          return false;
        }
        // Otherwise check that at least one of the property values matches
        // In most cases we only have one property to check unless we are searching the whole object, but multiple props are allowed
        return allValuesToCheck.some(value => stringMatchFn(valueToMatch, value));
      });
    }
    else {
      return resources;
    }
  };

  /** Computed Filter Top Three Cards for Localized Language */
  function filteredTopThree() {
    let ret: Resource[] = [];
    for (let i = 0; i < topThreeAll.value.length; i++) {
      for (let ii = 0; ii < (<any>topThreeAll.value[i]).length; ii++) {
        ret = filterResourcesByPropValues(CURRENT_LANG, (<any>topThreeAll.value[i]), ["language"], checkValueEquals);
      }
      // If localized Document exists then push it up to display
      if(ret[0] !== undefined) {
        console.warn('Document is not translated');
        topThreeLang.value.push(ret[0]);
      }
    }
  }

  /**  Computed reference: The list of filtered resources  */
  const filteredResources = computed((): Resource[] => {
    let ret = filterResourcesByPropValues(CURRENT_LANG, allResources.value, ["language"], checkValueEquals);
    ret = filterResourcesByPropValues(filters.searchTerm, ret, (["title", "description"].concat(ClassificationProps)) as ResourceKey[], checkValueContains);

    for (let classificationKey of ClassificationProps) {
      ret = filterResourcesByPropValues(filters[classificationKey], ret, [classificationKey], checkValueEquals);
    }
    return ret;
  });

  /**
   * Gets a list of unique values for the given `Classification` property
   * @param propname - The `Resource` property to extract classification values from
   * @param resources - The full list of resources
   * @returns - A list of unique classification values for the specified property in `propname` 
   */
  function getUniqueClassificationsForProperty(propname: ClassificationProps, resources: Resource[]): string[] {
    var classifications: string[] = [];

    classifications = resources
      .flatMap<string>((resource: Resource) => { return resource[propname] ?? []; }) // get all classification values
      .filter((val: string, idx: number, arr: string[]): boolean => (isString(val) && arr.indexOf(val) === idx)) // filter for unique values
      .sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()));
    // Sets which dropdowns are empty
    isEmptyClassification[propname] = (classifications.length === 0);

    return classifications;
  }
  
  /**  Computed reference: 
   * - An object that maps each `ClassificationProps` key to a list of unique values used as options in the dropdown filters  
   */
  const options = computed(() => {
    // console.log("allResources", allResources.value);
    return ClassificationProps.reduce((optionsObject, propname) => {
      let classifications = getUniqueClassificationsForProperty(propname, filteredResources.value);
      // console.log(propname, classifications);
      optionsObject[propname] = classifications;
      return optionsObject;
    }, {} as ClassificationsRecord<string[]>);
  });

  /**  Computed reference: 
   * - An object that maps a `ClassificationProps` key to it's filter value that is used to filter out resources that do not match.
   * - If the filter value is an empty string, the `propname` key (aka the filter name) is not set on the object.  
   */
  const activeFilters = computed(() => {
    return FilterProps.reduce((resultObj, propname: FilterProps) => {
      if (filters[propname] !== '') {
        resultObj[propname] = filters[propname];
      }
      return resultObj;
    }, {} as Record<FilterProps, string>);
  });

  /** Computed Reference
   * - Contains a list of filters that are active.
   * - This is essentially just a list of keys from the `activeFilters` object.
   */
  const activeFilterKeys = computed<FilterProps[]>(() => {
    return Object.keys(activeFilters.value);
  });

  /** Computed Reference
   * - The total number of pages needed to display each card for resources in `filteredResources` with `RESULTS_PER_PAGE` cards per page.
   */
  const totalNumberOfPages = computed(() => {
    return Math.ceil(filteredResources.value.length / RESULTS_PER_PAGE);
  });

  /** Computed Reference
   * - The paginated subset of resources to display on the current page.
   */
  const jsonDataResources = computed(() => {
    var end = currentPage.value * RESULTS_PER_PAGE;
    if (end > filteredResources.value.length) end = filteredResources.value.length;
    return filteredResources.value.slice((currentPage.value - 1) * RESULTS_PER_PAGE, end).reverse();
  });

  /**
   * Returns a default thumbnail for the specified resource type
   * @param variation - The variation of the resource
   */
  function getGenericThumbForVariation(variation: string): string {
    switch (variation) {
      case "Document": 
        return 'https://static.mastercontrol.com/assets/persist/images/document-thumbnail-generic.png';
      case "Video":    
        return 'https://static.mastercontrol.com/assets/persist/images/video-thumbnail-generic.jpg';
      case "Event":    
        return 'https://static.mastercontrol.com/assets/persist/images/document-thumbnail-generic.png';
      case "Link":
        return 'https://static.mastercontrol.com/assets/persist/images/link-thumbnail-generic.png';
      default:         
        return 'https://static.mastercontrol.com/assets/persist/images/document-thumbnail-generic.png';
    }
  }

  /**
   * Sets a filter value
   * @param event - The event object that is triggered whenever a filter is changed
   * @param event.filtername - The name of the filter that matches a key in the `filters` object
   * @param event.option - The selected value from the filter dropdown
   */
  function setFilter(event: { filtername: FilterProps, option: string }) {
    // console.warn(event);
    filters[event.filtername] = event.option;
  }

  /**
   * Removes an active filter (by setting it to empty string)
   * @param event - The event object that is triggered when a filter is removed
   * @param event.filtername - The name of the filter that matches a key in the `filters` object
   */
  function removeFilter(event: { filtername: FilterProps }) {
    filters[event.filtername] = '';
  }

  /**
   * Watches the filteredResources array for changes and sets us to the first page when something changes
   */
  watch(filteredResources, () => { currentPage.value = 1; });
  const { t } = useTranslation();

  /* Watches for URL with Query string for filtering */
  const filterNames = ["hubType", "solution", "industry"] as const;
  type FilterNames = typeof filterNames[number];

  const queryFilterNames = ["type", "solution", "industry"] as const;
  type QueryFilterNames = typeof queryFilterNames[number];

  function getCorrectFilterName(x: QueryFilterNames): FilterNames | null {
    switch (x) {
      case "type":
        return "hubType";
      case "solution":
        return "solution";
      case "industry":
        return "industry";
      default:
        return null;
    }
  }

  function toTitleCase(s: string) {
    return s.replace (/^[_+]*(.)/, (_, c) => c.toUpperCase()).replace (/[_+]+(.)/g, (_, c) => ' ' + c.toUpperCase());
  }

  function splitQuery(el: string | null) {
    if (el === null) return null;
    let kindSplit = el.split('=');
    kindSplit[0] = kindSplit[0].toLowerCase();
    if((queryFilterNames as readonly string[]).includes(kindSplit[0])) {
      let name = getCorrectFilterName(kindSplit[0] as QueryFilterNames);
      if (name === null) return null;
      let option = toTitleCase(kindSplit[1]);
      let filterData = { filtername: name, option: option };
      return filterData;
    } else {
      return null;
    }
  }

  function checkForUrlQuery() {
    const urlParams = window.location.search.substring(1);
    const splitParams = urlParams.split('&');
    // console.warn(splitParams);
    for (const item of splitParams) {
      let filterData = splitQuery(item);
      if(filterData !== null) {
        setFilter(filterData);
      }
    }
  }
</script>

<template>
  <!-- top content -->
  <div class="top-content neutral">
    <div class="container">
      <div class="mcui-breadcrumb d-flex flex-row align-items-center">
        <a class="bc-link" href="/">
          <svg 
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="#FFF"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.9492 5.22321V5.25C12.9746 5.27679 13 5.30357 13 5.35714C13 5.41071 12.9746 5.4375 12.9746 5.46429L12.6445 5.89286C12.6191 5.94643 12.5937 5.94643 12.543 5.94643C12.4922 5.94643 12.4668 5.94643 12.4414 5.89286L11.375 5.00893V11.1429C11.375 11.3839 11.2734 11.5982 11.1211 11.7589C10.9687 11.9196 10.7656 12 10.5625 12H7.61719C7.51562 12 7.43945 11.9732 7.38867 11.9196C7.33789 11.8661 7.3125 11.7857 7.3125 11.6786V8.14286H5.6875V11.6786C5.6875 11.7857 5.63672 11.8661 5.58594 11.9196C5.53516 11.9732 5.45898 12 5.38281 12H2.4375C2.20898 12 2.00586 11.9196 1.85352 11.7589C1.70117 11.5982 1.625 11.3839 1.625 11.1429V5.00893L0.558594 5.89286C0.507812 5.94643 0.482422 5.94643 0.431641 5.94643C0.380859 5.94643 0.355469 5.91964 0.355469 5.86607L0.0253906 5.46429C0 5.4375 0 5.41071 0 5.35714C0 5.30357 0 5.27679 0.0507812 5.22321L5.99219 0.1875C6.14453 0.0803571 6.29687 0 6.5 0C6.67773 0 6.85547 0.0803571 7.00781 0.1875L10.5625 3.21429V1.875C10.5625 1.82143 10.5625 1.79464 10.6133 1.76786C10.6387 1.74107 10.6641 1.71429 10.7148 1.71429H11.2227C11.248 1.71429 11.2734 1.74107 11.3242 1.76786C11.3496 1.79464 11.375 1.82143 11.375 1.875V3.91071L12.9492 5.22321ZM10.5625 11.1429V4.3125L6.60156 0.9375C6.52539 0.883929 6.44922 0.883929 6.39844 0.9375L2.4375 4.3125V11.1429H4.875V7.60714C4.875 7.52679 4.90039 7.44643 4.95117 7.39286C5.00195 7.33929 5.07812 7.28571 5.17969 7.28571H7.82031C7.89648 7.28571 7.97266 7.33929 8.02344 7.39286C8.07422 7.44643 8.125 7.52679 8.125 7.60714V11.1429H10.5625Z" />
          </svg>
        </a>
        <svg 
          class="separator"
          width="5"
          height="9"
          viewBox="0 0 5 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0.792271 0.061086C0.7343 0.020362 0.676328 0 0.618357 0C0.541063 0 0.502415 0.020362 0.463768 0.061086L0.0772947 0.468326C0.0193237 0.50905 0 0.570136 0 0.631222C0 0.71267 0.0193237 0.773756 0.0772947 0.81448L3.57488 4.5L0.0772947 8.18552C0.0193237 8.24661 0 8.30769 0 8.36878C0 8.45023 0.0193237 8.49095 0.0772947 8.53167L0.463768 8.93891C0.502415 8.97964 0.541063 9 0.618357 9C0.676328 9 0.7343 8.97964 0.792271 8.93891L4.83092 4.68326C4.86957 4.64253 4.90821 4.58145 4.90821 4.5C4.90821 4.43891 4.86957 4.37783 4.83092 4.31674L0.792271 0.061086Z" />
        </svg>
        <a class="bc-link" href="/resource-center/">
          {{ t(resourceCenterTextEmun.ResourceCenter) }}
        </a>
      </div>
      <h1 class="primary-heading">
        {{ t(resourceCenterTextEmun.MCResourceCenter) }}
      </h1>
      <div v-if="loaded" 
        v-cloak 
        id="top-cards"
        class="top-cards-wrpr"
      >
        <h2 class="featured-sub-head">{{ t(resourceCenterTextEmun.FeaturedContent) }}</h2>
        <!-- Top Three Cards go here -->
        <template v-for="c in topThreeLang" :key="c.id + c.hubType">
          <a v-if="isObject(c)" 
            class="resource-card"
            :class="c.hubType.replace(/\s+/g, '-').toLowerCase() + '-parent'"
            :href="c.landingPageUrl"
            :target="c.isExternal ? '_blank' : ''" 
            :rel="c.isExternal ? 'noopener noreferrer' : ''"
          >
            <div class="img-wrpr">
              <imageWithFallback v-if="c.hubType === 'Link'"
                class="link-img"
                :src="c.thumbnail"
                :alt="c.thumbnailAltText"
                :fallback-src="getGenericThumbForVariation(c.variation)"
                width="200"
              />
              <imageWithFallback v-else
                class="resource-img"
                :src="c.thumbnail"
                :alt="c.thumbnailAltText"
                :fallback-src="getGenericThumbForVariation(c.variation)"
              />

              <div v-if="c.hubType.includes('Video')" class="play-button">
                <span>{{ t(resourceCenterTextEmun.Play) }}</span>
                <svg xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                >
                  <path d="M7 5L0.25 9.33013L0.25 0.669872L7 5Z" />
                </svg>
              </div>
            </div>
            <div class="asset-type-label" :class="c.hubType.replace(/\s+/g, '-').toLowerCase()">
              {{ c.hubType }}
            </div>
            <div class="resource-title" z-index:1>
              {{ c.title }}
            </div>
          </a>
        </template>
      </div>
    </div>
  </div>

  <div v-if="loaded" 
    v-cloak
    id="hub-filters"
    class="hub-filters"
  >
    <div class="container">
      <div class="row-one">
        <div class="left">
          <div class="dropdown d-md-none">
            <div id="dropdownMobileButton" 
              class="dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ t(resourceCenterTextEmun.Filter) }}
            </div>
            <div class="dropdown-menu r-scroll" aria-labelledby="dropdownMobileButton">
              <mobile-filter-dropdown 
                v-for="optKey in ClassificationProps"
                :key="optKey"
                :title="getDropdownTitle(optKey)"
                :opts="options[optKey]"
                :filtername="optKey"
                :has-no-options="isEmptyClassification[optKey]"
                @clicked="setFilter($event)"
              />
            </div>
          </div>
          <desktop-filter-dropdown 
            v-for="optKey in ClassificationProps"
            :key="optKey"
            :title="getDropdownTitle(optKey)"
            :opts="options[optKey]"
            :filtername="optKey"
            :has-no-options="isEmptyClassification[optKey]"
            @clicked="setFilter($event)"
          />
        </div>

        <div class="right">
          <card-or-list-view-toggle v-model:display-as-list="displayAsList" />
          <searchBox v-model="filters.searchTerm" />
        </div>
      </div>

      <div class="row-two">
        <div class="active-filter-wrpr">
          <div v-show="activeFilterKeys.length > 0">
            {{ t(resourceCenterTextEmun.Filters) }}
          </div>
          <div id="active-filters" class="active-filters">
            <filter-pill v-for="key of activeFilterKeys" 
              :key="key"
              :title="activeFilters[key]"
              :filtername="key"
              @remove="removeFilter($event)" 
            />
          </div>
        </div>
        <results-pagination v-if="jsonDataResources.length > 0"
          v-model:current-page="currentPage"
          v-model:page-count="totalNumberOfPages"
          class="pages top"
        />
      </div>
    </div>
  </div>

  <div class="container">
    <div v-if="loaded" 
      v-cloak
      id="card-container"
      class="card-container"
      :class="displayAsList === true ? 'list-view' : 'card-view'"
    >
      <template v-for="x in jsonDataResources.slice().reverse()" :key="x.id + x.hubType">
        <resource-card :resource="x" :generic-thumb-src="getGenericThumbForVariation(x.variation)" />
      </template>

      <div v-if="jsonDataResources.length === 0 && loaded"
        id="no-results"
        class="p-5 mt-5 flex-column align-items-center justify-content-center"
      >
        <p>{{ t(resourceCenterTextEmun.NoMatchesFound) }}</p>
        <p>{{ t(resourceCenterTextEmun.ClearFilters) }}</p>
      </div>

      <div class="row-bottom">
        <results-pagination v-if="jsonDataResources.length > 0"
          v-model:current-page="currentPage"
          v-model:page-count="totalNumberOfPages"
          class="pages"
        />
      </div>
    </div>
  </div>

  <div v-if="!loaded"
    id="loading" 
    class="p-5 mt-5 flex-column align-items-center justify-content-center"
  >
    <p>{{ t(resourceCenterTextEmun.LoadingResources) }}</p>
  </div>
</template>