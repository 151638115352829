import { createEnumFromKeys} from "@mcwd/typescript-type-guards";
const resourceCenterTextKeys = [
 'Type',
 'Topic',
 'Solution',
 'Industry',
 'ResourceCenter',
 'MCResourceCenter',
 'Play',
 'Filter',
 'Filters',
 'LoadingResources',
 'SearchResources',
 'CardView',
 'ListView',
 'NoMatchesFound',
 'ClearFilters',
 'RemoveFilters',
 'FeaturedContent',
 'PageIndicator'
] as const;
type resourceCenterTextKeysTypes = typeof resourceCenterTextKeys[number];
export const resourceCenterTextEmun = createEnumFromKeys(resourceCenterTextKeys);
export type ResourceCenterTranslationObj = {
  readonly [Key in resourceCenterTextKeysTypes]: string;
};