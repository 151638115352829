<script setup lang="ts">
  import {ref} from 'vue';
  import { useTranslation } from "i18next-vue";
  import { resourceCenterTextEmun } from "../translations/lang/translation-keys.js";
  let emit = defineEmits<{ (e: 'update:displayAsList', value: boolean): void }>();

  const displayAsList = ref(false);

  function setDisplayAsList(value: boolean){
    displayAsList.value = value;
    emit("update:displayAsList", value);
  }
  const { t } = useTranslation();
</script>

<template>
  <div class="display-card-list d-none d-md-flex">
    <svg class="d-card" 
      :class="{ active: displayAsList === false }"
      data-view="card-view" 
      width="24"
      height="22"
      viewBox="0 0 24 22" 
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="setDisplayAsList(false)"
    >
      <title>{{ t(resourceCenterTextEmun.CardView) }}</title>
      <path d="M13.875 0.5H22.875C23.4963 0.5 24 1.00367 24 1.625V9.125C24 9.74633 23.4963 10.25 22.875 10.25H13.875C13.2537 10.25 12.75 9.74633 12.75 9.125V1.625C12.75 1.00367 13.2537 0.5 13.875 0.5ZM10.125 0.5H1.125C0.503672 0.5 0 1.00367 0 1.625V9.125C0 9.74633 0.503672 10.25 1.125 10.25H10.125C10.7463 10.25 11.25 9.74633 11.25 9.125V1.625C11.25 1.00367 10.7463 0.5 10.125 0.5ZM0 12.875V20.375C0 20.9963 0.503672 21.5 1.125 21.5H10.125C10.7463 21.5 11.25 20.9963 11.25 20.375V12.875C11.25 12.2537 10.7463 11.75 10.125 11.75H1.125C0.503672 11.75 0 12.2537 0 12.875ZM13.875 21.5H22.875C23.4963 21.5 24 20.9963 24 20.375V12.875C24 12.2537 23.4963 11.75 22.875 11.75H13.875C13.2537 11.75 12.75 12.2537 12.75 12.875V20.375C12.75 20.9963 13.2537 21.5 13.875 21.5Z" fill="#C3C5C8" />
    </svg>
    <svg class="d-list" 
      :class="{ active: displayAsList === true }"
      data-view="list-view"
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="setDisplayAsList(true)"
    >
      <title>{{ t(resourceCenterTextEmun.ListView) }}</title>
      <path d="M6.99998 9.125V12.875C6.99998 13.4963 6.49631 14 5.87498 14H1.125C0.503672 14 0 13.4963 0 12.875V9.125C0 8.50367 0.503672 8 1.125 8H5.87498C6.49631 8 6.99998 8.50367 6.99998 9.125ZM0 16.625V20.375C0 20.9963 0.503672 21.5 1.125 21.5H5.87498C6.49631 21.5 6.99998 20.9963 6.99998 20.375V16.625C6.99998 16.0037 6.49631 15.5 5.87498 15.5H1.125C0.503672 15.5 0 16.0037 0 16.625ZM5.87498 0.5H1.125C0.503672 0.5 0 1.00367 0 1.625V5.375C0 5.99633 0.503672 6.5 1.125 6.5H5.87498C6.49631 6.5 6.99998 5.99633 6.99998 5.375V1.625C6.99998 1.00367 6.49631 0.5 5.87498 0.5ZM9.62498 21.5H22.875C23.4963 21.5 24 20.9963 24 20.375V16.625C24 16.0037 23.4963 15.5 22.875 15.5H9.62498C9.00366 15.5 8.49998 16.0037 8.49998 16.625V20.375C8.49998 20.9963 9.00366 21.5 9.62498 21.5ZM8.49998 1.625V5.375C8.49998 5.99633 9.00366 6.5 9.62498 6.5H22.875C23.4963 6.5 24 5.99633 24 5.375V1.625C24 1.00367 23.4963 0.5 22.875 0.5H9.62498C9.00366 0.5 8.49998 1.00367 8.49998 1.625ZM9.62498 14H22.875C23.4963 14 24 13.4963 24 12.875V9.125C24 8.50367 23.4963 8 22.875 8H9.62498C9.00366 8 8.49998 8.50367 8.49998 9.125V12.875C8.49998 13.4963 9.00366 14 9.62498 14Z" fill="#C3C5C8" />
    </svg>
  </div>
</template>