<script setup lang="ts">
  import { ref } from 'vue';
  import {FilterProps} from "./shared-types.js";
  import { useTranslation } from "i18next-vue";
  import { resourceCenterTextEmun } from "../translations/lang/translation-keys.js";
  const props = defineProps<{
    opts: string[],
    title: string,
    filtername: FilterProps,
    hasNoOptions: boolean
  }>();

  const selected = ref('');

  const emit = defineEmits<(e: 'clicked', { filtername, option }: { filtername: FilterProps, option: string }) => void>();

  function filterUpdated(option: string) {
    selected.value = '';
    emit('clicked', {
      filtername: props.filtername,
      option
    });
  }
  function switchSelect(event: Event) {
    filterUpdated((event.target as HTMLOptionElement).value);
  }
  const { t } = useTranslation();
</script>

<template>
  <div class="select-wrpr">
    <select :id="'mobile-' + filtername" 
      v-model="selected" 
      class="filter-select"
      :name="'mobile-' + filtername" 
      @change="switchSelect"
    >
      <option value="" selected hidden>
        {{ title }}
      </option>
      <option v-for="opt in opts" :key="opt" :value="opt">
        {{ opt }}
      </option>
      <option class="dropdownEmpty" disabled :class="{ empty: hasNoOptions }">
        {{ t(resourceCenterTextEmun.RemoveFilters) }}
      </option>
    </select>
  </div>
</template>