<script setup lang="ts">
  import { computed, toRef } from 'vue';
  import pageIndicator from './page-indicator.vue';
  type PropsType = { pageCount: number, currentPage: number };
  const props = withDefaults(defineProps<PropsType>(), { pageCount: 1, currentPage: 1 });
  const propsCurrentPage = toRef(props, 'currentPage');
  
  const emit = defineEmits<(e: 'update:currentPage', currentPage: number) => void>();

  const currentPageWrapper = computed({
    get() {
      return propsCurrentPage.value;
    },
    set(value) {
      emit('update:currentPage', value);
    }
  });
  
  const pageNavigation = {
    firstPage(){
      currentPageWrapper.value = 1;
    },
    lastPage(){
      currentPageWrapper.value = props.pageCount;
    },
    nextPage(){
      if (currentPageWrapper.value < props.pageCount) { currentPageWrapper.value++; }
    },
    previousPage(){
      if (currentPageWrapper.value > 1) { currentPageWrapper.value--; }
    }
  };
</script>

<template>
  <div>
    <svg class="pages-arrow arrow-to-left" 
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      @click="pageNavigation.firstPage()"
    >
      <path d="M9.6875 13.5938V13.6328C9.76562 13.7109 9.84375 13.75 10 13.75C10.1172 13.75 10.2344 13.7109 10.3516 13.5938L11.1328 12.8516C11.2109 12.7734 11.25 12.6562 11.25 12.5C11.25 12.3828 11.1719 12.2656 11.0938 12.1875L7.30469 8.51562H17.0312C17.1484 8.51562 17.2656 8.47656 17.3438 8.39844C17.4219 8.32031 17.5 8.20312 17.5 8.04688V6.95312C17.5 6.83594 17.4219 6.71875 17.3438 6.64062C17.2656 6.5625 17.1484 6.48438 17.0312 6.48438H7.30469L11.0938 2.8125C11.1719 2.73438 11.25 2.65625 11.25 2.5C11.25 2.38281 11.2109 2.26562 11.1328 2.14844L10.3516 1.36719C10.2344 1.28906 10.1172 1.25 10 1.25C9.84375 1.25 9.76562 1.28906 9.6875 1.36719L3.90625 7.14844C3.78906 7.26562 3.75 7.38281 3.75 7.5C3.75 7.65625 3.78906 7.73438 3.90625 7.8125L9.6875 13.5938ZM2.03125 14.5312C2.03125 14.6875 1.95312 14.8047 1.875 14.8828C1.79688 14.9609 1.67969 15 1.5625 15H0.46875C0.3125 15 0.195312 14.9609 0.117188 14.8828C0.0390625 14.8047 0 14.6875 0 14.5312V0.46875C0 0.351562 0.0390625 0.234375 0.117188 0.15625C0.195312 0.078125 0.3125 0 0.46875 0H1.5625C1.67969 0 1.79688 0.078125 1.875 0.15625C1.95312 0.234375 2.03125 0.351562 2.03125 0.46875V14.5312Z"
        fill="#3B94A3"
      />
    </svg>
    <svg class="pages-arrow arrow-left" 
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="pageNavigation.previousPage()"
    >
      <path d="M5.9375 12.3438V12.3828C6.01562 12.4609 6.09375 12.5 6.25 12.5C6.36719 12.5 6.48438 12.4609 6.60156 12.3438L7.38281 11.6016C7.46094 11.5234 7.5 11.4062 7.5 11.25C7.5 11.1328 7.42188 11.0156 7.34375 10.9375L3.55469 7.26562H13.2812C13.3984 7.26562 13.5156 7.22656 13.5938 7.14844C13.6719 7.07031 13.75 6.95312 13.75 6.79688V5.70312C13.75 5.58594 13.6719 5.46875 13.5938 5.39062C13.5156 5.3125 13.3984 5.23438 13.2812 5.23438H3.55469L7.34375 1.5625C7.42188 1.48438 7.5 1.40625 7.5 1.25C7.5 1.13281 7.46094 1.01562 7.38281 0.898438L6.60156 0.117188C6.48438 0.0390625 6.36719 0 6.25 0C6.09375 0 6.01562 0.0390625 5.9375 0.117188L0.15625 5.89844C0.0390625 6.01562 0 6.13281 0 6.25C0 6.40625 0.0390625 6.48438 0.15625 6.5625L5.9375 12.3438Z"
        fill="#3B94A3"
      />
    </svg>
    <pageIndicator :current-page="currentPage" :page-count="pageCount" />
    <svg class="pages-arrow arrow-right" 
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="pageNavigation.nextPage()"
    >
      <path d="M8.5625 0.117188C8.48438 0.0390625 8.36719 0 8.25 0C8.09375 0 7.97656 0.078125 7.89844 0.15625L7.11719 0.898438C7.03906 1.01562 7 1.13281 7 1.25C7 1.40625 7.03906 1.48438 7.15625 1.5625L10.9453 5.23438H1.21875C1.0625 5.23438 0.945312 5.3125 0.867188 5.39062C0.789062 5.46875 0.75 5.58594 0.75 5.70312V6.79688C0.75 6.95312 0.789062 7.07031 0.867188 7.14844C0.945312 7.22656 1.0625 7.26562 1.21875 7.26562H10.9453L7.15625 10.9375C7.03906 11.0156 7 11.1328 7 11.25C7 11.4062 7.03906 11.5234 7.15625 11.6016L7.89844 12.3828C7.97656 12.4609 8.09375 12.5 8.25 12.5C8.36719 12.5 8.48438 12.4609 8.5625 12.3438L14.3828 6.60156C14.4609 6.52344 14.5 6.40625 14.5 6.25C14.5 6.13281 14.4609 6.01562 14.3828 5.89844L8.5625 0.117188Z"
        fill="#3B94A3"
      />
    </svg>
    <svg class="pages-arrow arrow-to-right"
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="pageNavigation.lastPage()"
    >
      <path d="M8.3125 1.36719C8.23438 1.28906 8.11719 1.25 8 1.25C7.84375 1.25 7.72656 1.32812 7.64844 1.40625L6.86719 2.14844C6.78906 2.26562 6.75 2.38281 6.75 2.5C6.75 2.65625 6.78906 2.73438 6.90625 2.8125L10.6953 6.48438H0.96875C0.8125 6.48438 0.695312 6.5625 0.617188 6.64062C0.539062 6.71875 0.5 6.83594 0.5 6.95312V8.04688C0.5 8.20312 0.539062 8.32031 0.617188 8.39844C0.695312 8.47656 0.8125 8.51562 0.96875 8.51562H10.6953L6.90625 12.1875C6.78906 12.2656 6.75 12.3828 6.75 12.5C6.75 12.6562 6.78906 12.7734 6.90625 12.8516L7.64844 13.6328C7.72656 13.7109 7.84375 13.75 8 13.75C8.11719 13.75 8.23438 13.7109 8.3125 13.5938L14.1328 7.85156C14.2109 7.77344 14.25 7.65625 14.25 7.5C14.25 7.38281 14.2109 7.26562 14.1328 7.14844L8.3125 1.36719ZM15.9688 0.46875C15.9688 0.351562 16.0078 0.234375 16.0859 0.15625C16.1641 0.078125 16.2812 0 16.4375 0H17.5312C17.6484 0 17.7656 0.078125 17.8438 0.15625C17.9219 0.234375 18 0.351562 18 0.46875V14.5312C18 14.6875 17.9219 14.8047 17.8438 14.8828C17.7656 14.9609 17.6484 15 17.5312 15H16.4375C16.2812 15 16.1641 14.9609 16.0859 14.8828C16.0078 14.8047 15.9688 14.6875 15.9688 14.5312V0.46875Z"
        fill="#3B94A3"
      />
    </svg>
  </div>
</template>