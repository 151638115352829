import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-numbers" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode($setup["translation"], {
      translation: $setup.t($setup.resourceCenterTextEmun.PageIndicator)
    }, {
      "current-page-slot": _withCtx(() => [
        _createTextVNode(_toDisplayString($props.currentPage), 1 /* TEXT */)
      ]),
      "max-page-slot": _withCtx(() => [
        _createTextVNode(_toDisplayString($props.pageCount), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["translation"])
  ]))
}